export default {
    removePromotion(hasIncentive, numberDoses, table) {
        if(!hasIncentive || numberDoses.totalDosesWithPromotion == 0) {
            delete table.fields[3];
            return;
        }

        table.fields[3] = {key: 'withPromotion', label: 'Com fomento'};
    },

    removePromotionNoIncentive(hasIncentive, fields) {
        if(!hasIncentive) {
            delete fields[3];
            return;
        }

        fields[3] = {key: 'withPromotion', label: 'Com fomento'};
    },

    removePromotionDoses(doses, fields) { 
        if(!doses) {
            delete fields[3];
            return;
        }

        fields[3] = {key: 'withPromotion', label: 'Com fomento'};
    },

    removeWithoutPromotionDoses(doses, fields) { 
        if(!doses) {
            delete fields[2];
            return;
        }

        fields[2] = {key: 'noPromotion', label: 'Sem fomento'};
    },

    removeWithoutPromotion(numberDoses, table) {
        if(numberDoses.totalDosesWithoutPromotion == 0) {
            delete table.fields[2];
            return;
        }

        table.fields[2] = {key: 'noPromotion', label: 'Sem fomento'};
    },
}