<template>
  <section class="formCampanha mt-2">
        <validation-observer ref="campanhaRules">
            <b-form>
                <b-row>
                    <b-col lg="6" md="6" sm="12" class="pr-lg-0">
                        <b-form-group label="Nome da Campanha*" label-for="name-campanha">
                            <validation-provider
                                #default="{ errors }"
                                name="nameCampanha"
                                rules="required"
                            >
                                <b-form-input 
                                    :state="errors.length > 0 || form.errors.nameCampanha ? false:null"
                                    v-mask="('X'.repeat(200))"
                                    id="name-campanha" 
                                    v-model="form.nameCampanha"
                                    autocomplete="off"
                                    placeholder="Dê o nome da campanha"
                                />
                                <small v-if="errors[0]" class="text-danger d-block">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="form.errors.nameCampanha" class="text-danger">
                                    {{ form.errors.nameCampanha }}
                                </small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col lg="3" md="6" sm="12" class="pr-lg-0">
                        <validation-provider
                            #default="{ errors }"
                            name="Doenca"
                            rules="required"
                        >
                            <b-form-group 
                                label="Doença*" 
                                label-for="disease" 
                                :state="errors.length > 0 || form.errors.disease ? false:null">
                                <v-select 
                                    id="disease"
                                    :loading="loadingDoencas"
                                    :reduce="(option => option.id_doenca)"
                                    v-model="form.disease"
                                    variant="primary"
                                    :options="disease"
                                    @input="selectedDisease"
                                    label="nome_tecnico"
                                    placeholder="Selecione uma doença"
                                >
                                    <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger d-block">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="form.errors.disease" class="text-danger">
                                    {{ form.errors.disease }}
                                </small>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="5" md="6" sm="12">
                        <validation-provider
                            #default="{ errors }"
                            name="Vacina"
                            rules="required"
                        >
                            <b-form-group 
                                label="Vacina*" 
                                label-for="vaccines" 
                                :state="errors.length > 0 || form.errors.vaccines ? false:null">
                                <v-select 
                                    id="vaccines"
                                    v-model="form.vaccines"
                                    :loading="loadingVacinas"
                                    :options="vaccines"
                                    :closeOnSelect="false"
                                    :disabled="selectVaccines"
                                    multiselect
                                    multiple
                                    @input="selectedVaccines"
                                    @option:deselected="emitEventSelectedVaccines"
                                    :selectable="() => form.vaccines.length < 3"
                                    label="nome_tecnico"
                                    placeholder="Selecione uma ou mais"
                                >
                                    <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger d-block">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="form.errors.vaccines" class="text-danger">
                                    {{ form.errors.vaccines }}
                                </small>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" md="12" sm="12" class="mt-2">
                        <validation-provider
                            #default="{ errors }"
                            name="adhesion"
                            rules="required"
                        >
                        <adhesion-no-workers-input
                        @setPartialAdhesion="setPartialAdhesion"
                        />
                        <input type="hidden" name="adhesion" :value="adhesion">
                        <small v-if="errors[0]" class="text-danger d-block">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </section>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput,  BFormRadioGroup, BButton, BAlert } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';

import { busEvent } from '@/main';
import AdhesionNoWorkersInput from '@/views/components/custom/Inputs/AdhesionNoWorkersInput.vue';

export default {
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BAlert, BFormRadioGroup,
        ValidationProvider, ValidationObserver, vSelect,
        AdhesionNoWorkersInput
    },

    props: {
        removedVaccina: {
            required: false,
            type: Number
        }
    },

    data() {
        return {
            required, 
            saving: false,
            idCampaign: null,
            loadingVacinas: false,
            loadingDoencas: true,
            selectVaccines: true,
            adhesion: null,
            form: {
                nameCampanha: null,
                disease: null,
                vaccines: [],
                errors: {
                    nameCampanha: null, 
                    disease: null, 
                    vaccines: null
                }
            },
            disease: [],
            vaccines: []
        }
    },

    mounted() {
        this.$http.get(this.$api.saveAndGetDoenca(), {params: { ativo: true }}).then(({ data }) => {
            this.disease = data.sort((a, b) => a.nome_tecnico.localeCompare(b.nome_tecnico));
            this.loadingDoencas = false;
        });

        busEvent.$on('saveCampanha', () => {
            this.validateForm();
        });
    },

    methods: {
        validateForm() {
            this.$refs.campanhaRules.validate().then(success => {
                if (success) {
                    const ids_vacina = this.form.vaccines.map((vacina) => vacina.id_vacina);

                    const minimalDataCampaign = {
                        descricao: this.form.nameCampanha,
                        permite_adesao_parcial: this.adhesion,
                        id_doenca: this.form.disease,
                        id_vacina: ids_vacina
                    }
                    
                    busEvent.$emit('minimalDataCampaign', minimalDataCampaign); 
                }
            })
        },

        selectedDisease() {
            this.form.errors.disease = null;

            if(this.form.disease) {
                this.loadingVacinas = true;
                this.loadVaccines(this.form.disease);
                return;
            }

            this.form.vaccines = [];
            this.vaccines = [];

            this.emitEventSelectedVaccines();
        },

        loadVaccines(idDisease) {
            this.selectVaccines = true;
            this.loadingVacinas = true;

            this.form.vaccines = [];
            this.vaccines = [];

            this.emitEventSelectedVaccines();

            this.$http.get(this.$api.getVaccinesOfDisease(idDisease)).then(({ data }) => {
                this.vaccines = data;  

                this.selectVaccines = false;
                this.loadingVacinas = false;
            })
        },

        selectedVaccines() {
            this.emitEventSelectedVaccines();
        },

        emitEventSelectedVaccines() {
            this.$emit('selectedVaccines', {
                vaccines: this.form.vaccines,
                disease: this.form.disease
            });
        },
        setPartialAdhesion(value) {
            this.adhesion = value;
        }
    },

    watch: {
        removedVaccina(id_vacina) {
            this.form.vaccines = this.form.vaccines.filter(vaccine => vaccine.id_vacina !== id_vacina);
            this.emitEventSelectedVaccines();
        }
    }
}
</script>