export default {
    prepareDataToSaveReservation(doses, selectedNumberOfDoses) {
        let reservas = [];

        if(doses.reservationWithoutPromotion == 0 && doses.reservationWithPromotion == 0) {
            return null;
        }

        if(doses.reservationWithoutPromotion != 0) {
            reservas.push({
                id_incentivo: selectedNumberOfDoses.numberOfDosesWithoutPromotion.incentive,
                reserva: doses.reservationWithoutPromotion 
            });
        }

        if(doses.reservationWithPromotion != 0) {
            reservas.push({
                id_incentivo: selectedNumberOfDoses.numberOfDosesWithPromotion.incentive,
                reserva: doses.reservationWithPromotion
            });
        } 

        return reservas;
    },

    prepareDataSaveDoseDistribution(regions, selectedIncentives) {
        const regionWithoutReservation = regions.filter(region => region.idRegiao);

        let distributionRegion = [];

        const promotion = selectedIncentives.filter(inccentive => inccentive.nome !== 'Normal');
        const noPromotion = selectedIncentives.filter(inccentive => inccentive.nome == 'Normal');

        regionWithoutReservation.map((region) => {
            if(region.noPromotion && noPromotion && region.noPromotion != 0) {
                distributionRegion.push({
                    id_incentivo: noPromotion[0].id_incentivo,
                    id_regiao: region.idRegiao,
                    quantidade: region.noPromotion
                });
            }

            if(region.withPromotion && promotion && region.withPromotion != 0) {
                distributionRegion.push({
                    id_incentivo: promotion[0].id_incentivo,
                    id_regiao: region.idRegiao,
                    quantidade: region.withPromotion
                });
            }
        });

        return distributionRegion;
    },
}