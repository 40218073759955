export default {
    extractErrorMessages(errors) {
        let messages = '';

        Object.keys(errors).forEach((key) => {
            errors[key].map((message) => {
                messages = message + '\n';
            });
        });

        return messages;
    }
}