<template>
    <b-alert 
    :variant="variant"
    :show="true"
    >
    <div class="d-flex align-items-center shadow">
        <h4 class="alert-heading mr-1" :class="'text-'+variant">
            Permitir adesão sem cadastro prévio de colaboradores?
        </h4>
        <b-form-group class="m-0">
            <b-form-radio-group
                id="radio-button"
                v-model="valueAdhesion"
                name="adhesionPartial"
                :options="option"
                @input="emitEventPartialAdhesion"
                class="radio-campaign"
                :class="disabled ? 'custom-control-'+variant : 'custom-control-'+variant+'-campaign'"
                :disabled="disabled"
            />
        </b-form-group>
    </div>
    <div class="alert-body" v-if="valueAdhesion!=null">
        <span class="font-weight-normal" :class="'text-'+variant" v-if="valueAdhesion">
            Esta campanha permitirá adesões sem cadastro prévio dos colaboradores e não terá limite máximo de doses que a empresa pode aderir. Para a etapa de vacinação, será necessário cadastrar os colaboradores. Após lançar a campanha, essa opção não poderá ser alterada.
        </span>
        <span class="font-weight-normal" :class="'text-'+variant" v-else>
            Para aderir a essa campanha, as empresas terão que cadastrar os seus colaboradores antes. A adesão não poderá ter mais doses que a quantidade de colaboradores cadastrados. Após lançar a campanha essa ação não poderá ser desfeita.
        </span>
    </div>
    </b-alert>
  </template>
  
  <script>
  import { BRow, BCol, BForm, BFormGroup, BFormInput,  BFormRadioGroup, BButton, BAlert } from 'bootstrap-vue';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import vSelect from 'vue-select';
  
  export default {
      components: {
          BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BAlert, BFormRadioGroup,
          ValidationProvider, ValidationObserver, vSelect
      },
  
      props: {
        adhesion: {
            required: false,
        },
        disabled: {},
      },
  
      data() {
          return {
              option: [{
                  value: true,
                  text: "Sim"
              },
              {
                  value: false,
                  text: "Não"
              },
              ],
              variant: 'custom-blue',
              valueAdhesion: null
          }
      },
      mounted() {
        this.valueAdhesion = this.adhesion
        this.setVariant(this.adhesion);
      },
      methods: {
        emitEventPartialAdhesion() {
            this.$emit('partialAdhesion', this.adhesion);
        },
        setVariant(value){
            let variantClass = 'custom-blue';
            if(value){
                variantClass = 'success';
            }
            if(value === false){
                variantClass = 'warning';
            }
            this.variant = variantClass;
        }
      },
  
      watch: {
          valueAdhesion(value) {
            this.setVariant(value)
            this.$emit('setPartialAdhesion', value)
          },
          adhesion(){
            this.valueAdhesion = this.adhesion
          }
      }
  }
  </script>

  <style>


.radio-campaign .custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after, .custom-radio .custom-control-label::before, .custom-radio .custom-control-label::after {
  left: 60px;
}

.radio-campaign .custom-control-input {
  left: 60px;
}

.radio-campaign .custom-checkbox.custom-control, .custom-radio.custom-control {
  padding: 0 1.5rem;
}

</style>