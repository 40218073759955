<template>
    <div class="content-wrapper">
        <PageHeader screenName="Cadastrar Campanha" :linkItems="linkItems"/>

        <SucessoModal
            :modalShow="modalSuccess.showModal"
            :typeModal="modalSuccess.typeModal"
            :caseModal="modalSuccess.caseModal"
            @confirmed="redirectToList(false)"
        />

        <ModalShowError
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :infoText="modalError.infoText"
            @confirmed="closeModals"
        />

        <DepartamentoModal
            :modalShow="modalErroDepartamento.showModal"
            :typeModal="modalErroDepartamento.caseModal"
            @confirmed="modalErroDepartamento.action"
        />

        <ScheduleModal
            :modalShow="scheduleModal.showModal"
            :idCampaign="scheduleModal.idCampaign"
            @closeModalSchedule="closeModals"
            @canceledScheduleCampaign="handleCanceledScheduleCampaign"
            @savedSchedule="openModalSuccess"
        />

        <MinimalInformationCampaign 
            @changeVaccines="changeVaccines"
            @numberOfDoses="handleChangeNumberOfDoses"
            :incentives="incentives"
            :noIncentive="noIncentive"
            :alertCanceledScheduleCampaign="alertCanceledScheduleCampaign"
        />

        <VaccinePricingAndDoseDistribution 
            :selectedVaccines="selectedVaccines"
            :incentives="incentives"
            :numberOfDoses="selectedNumberOfDoses"
            @noRegion="handleNoRegion"
            @noUnit="handleNoUnit"
            @errorPrecificationDistributionDoses="handleErrosPrecificationDistributionDoses"
            @errorNumberDoses="handleErrorDoses"
            @validDoseDistribution="handleValidDistribution"
        /> 

        <BottomButtons />
    </div>
</template>

<script>
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import MinimalInformationCampaign from './FormGroups/MinimalInformationCampaign.vue';
import VaccinePricingAndDoseDistribution from './FormGroups/VaccinePricingAndDoseDistribution.vue';
import DepartamentoModal from '@/views/components/custom/modals/ModalErrorDepartamento.vue';
import ScheduleModal from '@/views/custom-pages/Campanhas/Schedule/CampaignScheduleRegister.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import ModalShowError from '@/views/components/custom/modals/ModalShowError.vue';
import BottomButtons from '@/views/components/custom/ButtonsCampanha/BottomButtons.vue';

import payloadService from '../Services/PreparePayloadCampaign';
import HandleErrorMessagesHelper from '@/views/custom-pages/Campanhas/Helpers/HandleErrorMessagesHelper';

import { busEvent } from '@/main';

export default {
    title: 'Cadastrar Campanha',

    components: {
        PageHeader, MinimalInformationCampaign,
        VaccinePricingAndDoseDistribution, SucessoModal,
        ScheduleModal, DepartamentoModal, BottomButtons,
        ModalShowError
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Campanhas',
                    routeName: 'campanha-list' 
                },
                {
                    name: 'Cadastrar Campanha',
                    active: true
                }
            ],
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            modalError: {
                showModal: false,
                infoText: 'houve um erro ao salvar a campanha ',
                typeModal: 'erro-campaign-save',
            },
            modalErroDepartamento: {
                showModal: false,
                caseModal: 'sem-regiao',
                action: this.redirectToDepartamentoView
            },
            scheduleModal: {
                showModal: false,
                idCampaign: null
            },
            tentativasSalvar: 0,
            selectedVaccines: [],
            selectedNumberOfDoses: null,
            noIncentiveAlert: false,
            noIncentive: false,
            incentives: null,
            selectedDisease: null,
            alertCanceledScheduleCampaign: false,
            correctAllVaccines: 0,
            schedulingAfterSave: false,
            minimalDataCampaign: null,
            doseQuantificationData: null,
            vaccinePricingDataCampaign: null,
            doseDistributionRegion: null,
            doseDistributionUnidade: null
        }
    },

    mounted() {
        this.listenEventsDatacampaign();

        busEvent.$on('saveCampanha', (type) => {
            this.handleSaveCampanha(type);
        });
    },

    beforeDestroy() {
        busEvent.$off();
    },

    methods: {
        listenEventsDatacampaign() {
            busEvent.$on('minimalDataCampaign', (minimalDataCampaign) => {
                this.minimalDataCampaign = minimalDataCampaign;
            });
            busEvent.$on('doseQuantificationDataCampaign', (doseQuantificationData) => {
                this.doseQuantificationData = doseQuantificationData;
            });
            busEvent.$on('vaccinePricingDataCampaign', (vaccinePricingDataCampaign) => {
                this.vaccinePricingDataCampaign = vaccinePricingDataCampaign;
            });
            busEvent.$on('doseDistributionRegion', (doseDistributionRegion) => {
                this.doseDistributionRegion = doseDistributionRegion;
            });
            busEvent.$on('doseDistributionUnidade', (doseDistributionUnidade) => {
                this.doseDistributionUnidade = doseDistributionUnidade;
            });
        },

        handleSaveCampanha(type) {
            busEvent.$emit('savingCampaign', true);

            if(type == 'schedule') {
                this.schedulingAfterSave = true;
            } else {
                this.schedulingAfterSave = false;
            }
            this.saveCampaign();
        },

        saveCampaign() {
            if(
                this.minimalDataCampaign &&
                this.doseQuantificationData && 
                this.vaccinePricingDataCampaign &&
                this.doseDistributionRegion &&
                this.doseDistributionUnidade
            ) {
                const payload = {
                    descricao: this.minimalDataCampaign.descricao,
                    permite_adesao_parcial: this.minimalDataCampaign.permite_adesao_parcial,
                    id_doenca: this.minimalDataCampaign.id_doenca,
                    vacinas: payloadService.makePayload({
                        minimalInfo: this.minimalDataCampaign,
                        quantification: this.doseQuantificationData,
                        pricging: this.vaccinePricingDataCampaign,
                        distributionRegion: this.doseDistributionRegion,
                        distributionUnidade: this.doseDistributionUnidade
                    })
                }

                this.$http.post(this.$api.campanha(), payload).then(({ data }) => {
                    busEvent.$emit('savingCampaign', false);
                    this.openScheduleModal(data.id_campanha);
                }).catch(({ response }) => {
                    busEvent.$emit('savingCampaign', false);

                    if(response.status == 422) {
                        this.modalError.infoText = HandleErrorMessagesHelper.extractErrorMessages(response.data.errors);
                        this.modalError.showModal = true;
                    } else {
                        this.modalError.infoText = response.data.errors;
                        this.modalError.showModal = true;
                    }

                    this.minimalDataCampaign = null;
                    this.doseQuantificationData = null;
                    this.vaccinePricingDataCampaign = null;
                    this.doseDistributionRegion = null;
                    this.doseDistributionUnidade = null;
                })
            } else {
                setTimeout(() => {
                    if(this.tentativasSalvar <= 5) {
                        this.saveCampaign();
                        this.tentativasSalvar++;
                    } else {
                        busEvent.$emit('savingCampaign', false);
                        this.tentativasSalvar = 0;
                    }
                }, 100);
            }
        },

        handleChangeNumberOfDoses(numberOfDoses) {
            this.selectedNumberOfDoses = numberOfDoses;
        },

        changeVaccines(selectedVaccines) {
            if(!this.noIncentive) {
                this.selectedVaccines = selectedVaccines;
            }
            this.loadIncentives(selectedVaccines.disease);
        },

        loadIncentives(disease) {
            if(disease && this.selectedDisease !== disease) {
                this.noIncentive = false;
                this.selectedDisease = disease; 

                this.$http.get(this.$api.getIncentiveDisease(disease)).then(({ data }) => {
                    if(data.length == 0) {
                        this.noIncentiveAlert = !this.noIncentiveAlert;
                        this.noIncentive = true;
                    }

                    this.incentives = data;
                })
            }
        },

        handleErrosPrecificationDistributionDoses() {
            this.errorDoseOrPrecification = !this.errorDoseOrPrecification;
        },

        handleErrorDoses() {
            busEvent.$emit('disableButtonSave', {error: true})
            busEvent.$emit('savingCampaign', false);
        },
        
        handleValidDistribution() {
            busEvent.$emit('disableButtonSave', {error: false});
        },

        openModalSuccess() {
            this.modalSuccess.showModal = true;
        },

        closeModals() {
            this.scheduleModal.showModal = false;
            this.modalSuccess.showModal = false;
            this.modalError.showModal = false;
        },

        handleNoRegion(){
            this.modalErroDepartamento.caseModal = 'sem-regiao'
            this.modalErroDepartamento.action = this.redirectToDepartamentoView
            this.modalErroDepartamento.showModal = true
        },

        handleNoUnit(){
            this.modalErroDepartamento.caseModal = 'sem-unidade'
            this.modalErroDepartamento.action = this.redirectToUnidadeList
            this.modalErroDepartamento.showModal = true
        },

        redirectToList(withoutMessage = true) {
            if(withoutMessage) {
                this.$router.push({ name: 'campanha-list' , params: { nameCampaignNoSchedule: this.selectedVaccines.campaignName }});
                return;
            }

            this.$router.push({ name: 'campanha-list'});
            this.modalSuccess.showModal = false;
        },

        redirectToDepartamentoView() {
            this.$router.push({ name: 'departamento-view' });
            this.modalErroDepartamento.showModal = false;
        },

        redirectToUnidadeList() {
            this.$router.push({ name: 'unidade-list' });
            this.modalErroDepartamento.showModal = false;
        },

        openScheduleModal(idCampaign) {
            if(this.schedulingAfterSave) {
                this.scheduleModal.idCampaign = idCampaign;
                this.scheduleModal.showModal = true;
                this.alertCanceledScheduleCampaign = false;
            } else { this.openModalSuccess(); }
        },

        handleCanceledScheduleCampaign() {
            this.scheduleModal.showModal = false;
            this.redirectToList();
            this.alertCanceledScheduleCampaign = true;
        }
    },
    watch: {
        numberOfDoses: {
            handler (newValue){
                this.handleChangeNumberOfDoses(newValue);
            },
            deep:true,
        }
    }
}
</script>