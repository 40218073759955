export default {
    prepareDataDoseQuantification(busEvent, formQuantification) {
        let dataSaveDoseQuantification = [];

        formQuantification.map(vacinaDoses => {
            if(vacinaDoses.numberOfDosesWithoutPromotion.quantity) {
                dataSaveDoseQuantification.push(
                    {
                        id_vacina: vacinaDoses.id_vacina,
                        id_incentivo: vacinaDoses.numberOfDosesWithoutPromotion.incentive,
                        quantidade: vacinaDoses.numberOfDosesWithoutPromotion.quantity
                    }
                );
            }
            if(vacinaDoses.numberOfDosesWithPromotion.quantity) {
                dataSaveDoseQuantification.push(
                    {
                        id_vacina: vacinaDoses.id_vacina, 
                        id_incentivo: vacinaDoses.numberOfDosesWithPromotion.incentive,
                        quantidade: vacinaDoses.numberOfDosesWithPromotion.quantity
                    }
                );
            }
        });

        busEvent.$emit('doseQuantificationDataCampaign', dataSaveDoseQuantification);
    }
}