<template>
    <b-modal
        id="modal"
        size="sm"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
    >
        <div class="d-flex flex-column mx-2 my-3">
            <h3 class="mb-1">{{ nameRegion }}</h3>

            <span 
                v-if="loading" 
                class="spinner-border text-custom-blue spinner-border-md mb-2 mx-auto" 
                role="status" 
                aria-hidden="true"></span>

            <div v-else class="max-height mb-2  custom-container-uos">
                <p v-if="noRegion">Não há unidades nessa região</p>

                <p v-else v-for="unidade in unidades" :key="unidade.id_unidade" class="m-0 font-size-14">{{ unidade.descricao }}</p>
            </div>

            <b-button 
                id="close-modal" 
                class="width-button"
                @click="confirmAction" 
                variant="custom-blue"
            >OK</b-button>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
    components: {
        BModal, BButton
    },

    props: {
        idRegiao: {
            required: true,
            type: Number|null,
        },
        nameRegion: {
            required: true,
            type: String|null,
        },
        modalShow: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            loading: false,
            selectedIdRegiao: this.$props.idRegiao,
            noRegion: false,
            unidades: []
        }
    },

    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();

            this.$emit('confirmed', modalEvent);
        },

        loadOperatingUnits() {
            this.$http.get(this.$api.unidadeRegiao(this.$props.idRegiao)).then(({ data }) => {
                if(data.length > 0) {
                    this.noRegion = false;
                    this.unidades = data;
                } else { 
                    this.noRegion = true;
                }
                
                this.loading = false;
            })
        }
    },

    watch: {
        modalShow(show) {
            if(show) {
                this.loading = true;
                this.noRegion = false;
                this.loadOperatingUnits();
            }
        },

        idRegiao(regiao) {
            this.selectedIdRegiao = regiao;
        }
    }
}
</script>

<style scoped>
.font-size-14 {
    font-size: 14px;
}

.width-button {
    max-width: 25%;
    margin: auto;
}

.custom-container-uos {
    max-height: 320px;
    overflow-y: auto;
}
</style>