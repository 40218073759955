import { getInfoUserDepartamento } from "@core/utils/store/getStore";

export default {
    makePayload(campaignInfos) {
        let dataVaccine = [];

        campaignInfos.minimalInfo.id_vacina.map(idVaccine => {
            const estimativaVaccine = campaignInfos.quantification.filter(vaccineSelected => vaccineSelected.id_vacina == idVaccine);
            let estimativasSelected = [];

            estimativaVaccine.map(estimativa => {
                estimativasSelected.push({
                    id_incentivo: estimativa.id_incentivo,
                    quantidade: estimativa ? parseInt(estimativa.quantidade) : 0,
                    reserva: 
                        !getInfoUserDepartamento('divisao_por_regiao') 
                            ? this.prepareReservationRegion(campaignInfos.distributionUnidade, estimativa.id_incentivo, idVaccine)
                            : this.prepareReservationDepartament(campaignInfos.distributionRegion, estimativa.id_incentivo, idVaccine),
                    regioes: this.prepareRegions(
                        campaignInfos.distributionRegion, 
                        campaignInfos.distributionUnidade,
                        estimativa.id_incentivo, 
                        idVaccine
                    ),
                    preco: this.preparePricesVaccine(campaignInfos.pricging, estimativa.id_incentivo, idVaccine)
                });
            })

            dataVaccine.push({
                id_vacina: idVaccine,
                estimativas: estimativasSelected,
            });
        });

        return dataVaccine;
    },

    preparePricesVaccine(pricing, incentive, idVaccine) {
        let pricesVaccine = [];

        const priceVaccine = pricing.filter(vaccinePrice => vaccinePrice.id_vacina == idVaccine)[0];
        if(!priceVaccine) { return; }

        const pricesIncentive = priceVaccine.preco.filter(prices => prices.id_incentivo == incentive);

        pricesIncentive.map(categories => {
            pricesVaccine.push({
                id_tipo_preco: categories.id_tipo_preco,
                categoria: categories.categoria
            });
        });

        return pricesVaccine;
    },

    prepareRegions(distributionRegionData, distributionUnidadeData, incentive, idVaccine) {
        let distributionRegion = [];

        distributionRegionData.map(distribution => {
            if(distribution.idVaccine == idVaccine) {
                distribution.DistributionRegion.map(region => {
                    if(incentive == region.id_incentivo) {
                        const reservation = getInfoUserDepartamento('divisao_por_regiao')
                            ? this.prepareReservationRegion(distributionUnidadeData, incentive, idVaccine, region.id_regiao)
                            : 0;

                        const amountDoses = !getInfoUserDepartamento('divisao_por_regiao') 
                            ? region.quantidade - this.prepareReservationRegion(distributionUnidadeData, incentive, idVaccine, region.id_regiao)
                            : region.quantidade;

                        distributionRegion.push({
                            id_regiao: region.id_regiao,
                            quantidade: amountDoses,
                            reserva: reservation,
                            unidades: this.prepareUnidadesRegion(distributionUnidadeData, incentive, idVaccine, region.id_regiao)
                        })
                    }
                });
            }
        });

        return distributionRegion;
    },

    prepareUnidadesRegion(distributionUnidade, incentive, idVaccine, idRegion) {
        let unidades = [];

        distributionUnidade.map(distribution => {
            if(distribution.length != 0) {
                distribution.map(unit => {
                    if(unit.idRegion == idRegion && unit.idVaccine == idVaccine) {
                        unit.distribution.map(unidade => {
                            if(unidade.id_incentivo == incentive) {
                                unidades.push({
                                    id_unidade: unidade.id_unidade,
                                    quantidade: unidade.quantidade
                                });
                            } 
                        });
                    }
                });
            }
        }); 

        return unidades;
    },
    
    prepareReservationDepartament(distributionRegion, incentive, idVaccine) {
        let reservationDepartament = 0;

        distributionRegion.map(distribution => {
            if(distribution.idVaccine == idVaccine && distribution.reservation) {
                distribution.reservation.map(reservation => {
                    if(incentive == reservation.id_incentivo) {
                        reservationDepartament = reservation ? parseInt(reservation.reserva) : 0;
                    }
                });
            }
        });

        return reservationDepartament;
    },

    prepareReservationRegion(distributionUnidade, incentive, idVaccine, idRegion = null) {
        let reservationRegion = 0;

        distributionUnidade.map(distribution => {
            distribution.map(unit => {
                if((!idRegion || unit.idRegion == idRegion) && unit.idVaccine == idVaccine) {
                    unit.reserva.map(reservation => {
                        if(reservation.id_incentivo == incentive) {
                            reservationRegion = reservation ? parseInt(reservation.reserva) : 0;
                        } 
                    });
                }
            });
        }); 

        return reservationRegion;
    }
}
