export default {
    fillUnidadesByRegion(vueInstance, regionUnidade, dadosUnidade) {
        const unidades = [];

        unidades.push({
            idUnidade: null,
            descricao: 'Reserva',
            noPromotion: null,
            withPromotion: null,
            total: null,
            tooltip: true,
        });

        if(dadosUnidade) {
            dadosUnidade.map(unidade => {
                unidades.push({
                    idUnidade: unidade.id_unidade,
                    descricao: unidade.descricao,
                    noPromotion: null,
                    withPromotion: null,
                    total: null,
                    tooltip: false,
                });
            })
        }

        vueInstance.$set(regionUnidade, 'open', false);
        vueInstance.$set(regionUnidade, 'unidades', unidades);
        vueInstance.$set(regionUnidade, 'fields', [
            {key: 'actions', label: '', class: 'custom-width-actions-table px-1'},
            {key: 'descricao', label: 'Unidade', class: 'pl-1'},
            {key: 'noPromotion', label: 'Sem fomento'},
            {key: 'withPromotion', label: 'Com fomento'},
            {key: 'totalDoses', label: 'Total', class: 'text-center'},
        ]);
        this.updateSummary(vueInstance, regionUnidade);
    },

    updateSummary(vueInstance, regionUnidade) {
        vueInstance.$set(regionUnidade, 'dosesSummary', {
            totalDosesWithPromotion: regionUnidade.withPromotion ? parseInt(regionUnidade.withPromotion) : 0,
            totalDosesWithoutPromotion: regionUnidade.noPromotion ? parseInt(regionUnidade.noPromotion) : 0,

            reservationWithPromotion: 0,
            reservationWithoutPromotion: 0,

            totalDistributedDosesWithPromotion: 0,
            totalDistributedDosesWithoutPromotion: 0,

            controlDosesWithPromotion: regionUnidade.withPromotion ? parseInt(regionUnidade.withPromotion) : 0,
            controlDosesWithoutPromotion: regionUnidade.noPromotion ? parseInt(regionUnidade.noPromotion) : 0,
        });
    }
}