<template>
  <div class="card p-2">
    <ConfirmModal
      itemIdentifier="campanha"
      :modalShow="modal.showModal"
      :typeModal="modal.typeModal"
      @confirmed="redirecionarLista"
      @canceled="closeModalConfirm"
    />

    <section class="formHeader">
      <div>
        <b-button
          class="mr-2 mt-sm-2 mt-md-0"
          variant="custom-blue"
          :disabled="
            savingInfosCampanha || disableSaving || disableSavingNewVaccine
          "
          @click="saveInfosCampanha()"
        >
          <feather-icon icon="CheckIcon" class="mr-25" />
          <span>Salvar</span>
          <span
            v-if="savingInfosCampanha"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          ></span>
        </b-button>
        <b-button
          id="cancel-register-doenca"
          variant="outline-danger"
          class="mt-2 mt-sm-2 mt-md-0"
          @click.prevent="openModalConfirm"
        >
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import ConfirmModal from "@/views/components/custom/modals/ConfirmModal.vue";
import { setRegionConsulted } from "@core/utils/store/setStore";

import { busEvent } from "@/main";

export default {
  components: { BButton, ConfirmModal },

  data() {
    return {
      savingInfosCampanha: false,
      disableSaving: false,
      disableSavingNewVaccine: false,
      modal: {
        showModal: false,
        typeModal: "danger",
      },
    };
  },

  mounted() {
    busEvent.$on("disableButtonSave", (numberDoseError) => {
      this.disableSaving = numberDoseError.error;
    });

    busEvent.$on("disableButtonSaveNewVaccine", (booleanValue) => {
      this.disableSavingNewVaccine = booleanValue;
    });

    busEvent.$on("savingCampaign", (saving) => {
      this.savingInfosCampanha = saving;
    });
  },

  methods: {
    saveInfosCampanha() {
      busEvent.$emit('saveCampanha', 'save');
    },

    openModalConfirm() {
      this.modal.showModal = true;
    },

    redirecionarLista() {
      setRegionConsulted(null);
      this.$router.push({ name: "campanha-list" });
    },

    closeModalConfirm() {
      this.modal.showModal = false;
    },
  },
};
</script>

<style>
</style>