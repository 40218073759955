<template>
    <div>
        <CustomAlert v-if="liberarUnidades" variant="warning" message="É preciso definir se este departamento utiliza ou não regiões para distribuir as doses" :show="showAlert"/>
        <form-wizard
            ref="formDistributionRef"
            color="#2772C0"
            :title="null"
            :subtitle="null"
            finish-button-text="Submit"
            back-button-text="Previous"
            class="steps-transparent mb-3"
            @on-change="changeTab"
        >
            <tab-content title="Valores" icon="feather icon-dollar-sign" :beforeChange="() => blockAdvanceStep()">
                <VaccinePricingForm 
                    :hasPromotion="hasPromotion"
                    :numberOfDoses="selectedNumberOfDoses"
                    :vacinesEdited="vaccinesEditedSelected"
                    :idVacccine="idVaccineSelected"
                    :incentives="selectedIncentives"
                    @errorValidationPrecification="handleErrorValidationPrecification"
                />
            </tab-content>
            <tab-content :class="!hasRegion ? 'd-none' : ''" title="Regiões" icon="feather icon-database" :beforeChange="() => blockAdvanceStep()">
                <DoseDistribuitionRegion 
                    :hasPromotion="hasPromotion"
                    :incentives="selectedIncentives"
                    :numberOfDoses="selectedNumberOfDoses"
                    :hasRegion="hasRegion"
                    :vacinesEdited="vaccinesEditedSelected"
                    :idVacccine="idVaccineSelected"
                    @noRegion="handleNoRegion"
                    @noUnit="handleNoUnit"
                    @errorNumberDoses="handleErroDoses"
                    @validDoseDistribution="(isValid) =>{
                        setStatusRegionDistribution(isValid)
                        handleValidDistribution()
                        }"
                    @regionsDistributeUnidade="handleRegionDistributionUnidade"
                />
            </tab-content>
            <tab-content title="Unidade" icon="feather icon-disc">
                <DoseDistribuitionUnidadeDepartamento
                    :regionsSelected="selectedRegioes"
                    :hasPromotion="hasPromotion"
                    :incentives="selectedIncentives"
                    :idVacccine="idVaccineSelected"
                    :vacinesEdited="vaccinesEditedSelected"
                    :numberOfDoses="selectedNumberOfDoses"
                    @errorNumberDoses="handleErroDoses"
                    @validDoseDistribution="(isValid) =>{
                        setStatusUnitDistribution(isValid)
                        handleValidDistribution()
                        }"
                />
            </tab-content>
            <template slot="footer" slot-scope="props">
                <div v-if="!showButtom"></div>
                <b-button 
                    v-else
                    :disabled="disabledButtonWhenError || disabledButtonStep"
                    id="advance-stage" 
                    class="mr-2 mb-5" 
                    @click="proximaPagina(props)" 
                    variant="custom-blue"
                >
                    <div v-if="!liberarUnidades">
                        <feather-icon icon="ArrowRightIcon" class="mr-50"/>
                        <span class="align-middle">Avançar</span>
                    </div>
                    <div v-else>
                        <feather-icon icon="CheckIcon" class="mr-50"/>
                        <span class="align-middle">Liberar para Unidades</span>
                    </div>
                </b-button>
            </template>
        </form-wizard>
    </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import CustomAlert from '@/views/components/custom/timed-alert/CustomAlert.vue'
import VaccinePricingForm from '../Forms/VaccinePricingForm.vue';
import DoseDistribuitionRegion from '../Forms/DoseDistribuitionRegion.vue';
import { getInfoUserDepartamento } from "@core/utils/store/getStore";
import DoseDistribuitionUnidadeDepartamento from '../Forms/DoseDistributionOperatingUnit.vue';

import { busEvent } from '@/main';

export default {
    components: {
        FormWizard, TabContent, BButton, CustomAlert,
        VaccinePricingForm, DoseDistribuitionRegion, DoseDistribuitionUnidadeDepartamento
    },

    props: {
        idVaccine: {
            required: true,
            type: Number
        },
        vaccinesEdited: {
            required: true
        },
        incentives: {
            required: true,
            type: Object|null
        },
        numberOfDoses: {
            required: true,
            type: Object|null
        }
    },

    data() {
        return {
            idVaccineSelected: this.$props.idVaccine,
            selectedIncentives: this.$props.incentives,
            hasPromotion: false,
            hasRegion: getInfoUserDepartamento('divisao_por_regiao') ? true : false,
            selectedNumberOfDoses: this.$props.numberOfDoses,
            vaccinesEditedSelected: this.$props.vaccinesEdited,
            disabledButtonWhenError: true,
            hasRegionDistribution: false,
            liberarUnidades: false,
            showButtom: true, 
            disabledButtonStep: false,
            selectedRegioes: null,
            validDistributionUnit: true,
            validDistributionRegion: true,
            showAlert: false,
            noRegion: false,
        }
    },

    mounted() {
        if(this.selectedIncentives) {
            this.fillIncentive();
        }
        let sheet = window.document.styleSheets[0];
        if(!this.hasRegion){
            sheet.insertRule('.wizard-nav.wizard-nav-pills > li:nth-of-type(2) {display: none;}', sheet.cssRules.length);
        }else{
            sheet.insertRule('.wizard-nav.wizard-nav-pills > li:nth-of-type(2) {display: block;}', sheet.cssRules.length);
        }

        busEvent.$on('hasDistribution', this.handleHasDistribution);
    },

    beforeDestroy() {
        busEvent.$off('hasDistribution', this.handleHasDistribution);
    },


    methods: {
        proximaPagina(prop) {
            prop.nextTab();
            if(!this.hasRegion && prop.activeTabIndex == 0) {
                prop.nextTab();
            }
        },

        changeTab(_prevIndex, nextIndex) {
            if(nextIndex == 0) {
                this.liberarUnidades = false;
                this.disabledButtonStep = false; 
                this.showButtom = true;
            }
            if(nextIndex == 1) {
                busEvent.$emit('checkHasDistributionRegion', this.idVaccineSelected);

                this.disabledButtonStep = true;
                if(this.hasRegionDistribution) { 
                    this.disabledButtonStep = false; 
                }
                this.liberarUnidades = true;
                this.showButtom = true;
            }
            if(nextIndex == 2) {
                this.showButtom = false;
            }
        },

        handleErroDoses(idVaccine) {
            this.$emit('errorNumberDoses', idVaccine);
        },

        handleErrorValidationPrecification(error) {
            this.disabledButtonWhenError = error;
        },

        fillIncentive() {
            const promotion = this.selectedIncentives.filter(inccentive => inccentive.nome !== 'Normal');

            if(promotion.length > 0) {
                this.hasPromotion = true;
            }
        },

        handleNoRegion() {
            this.noRegion = true;
            this.showAlert = true;
            this.$emit('noRegion');
        },

        handleNoUnit() {
            this.$emit('noUnit');
        },

        handleRegionDistributionUnidade(regions) {
            this.selectedRegioes = regions[0];

            if(this.$refs.formDistributionRef.activeTabIndex == 0) { return; } 

            let onlyReservation = false;

            if(regions[0].length == 0) {
                onlyReservation = true;
            }

            if(regions[1] || onlyReservation) {
                this.disabledButtonStep = true;
                busEvent.$emit('canSaveSchedulePrice', [false, this.idVaccineSelected]);
            } else {
                this.disabledButtonStep = false;
                 busEvent.$emit('canSaveSchedulePrice', [true, this.idVaccineSelected]);
            }
        },

        handleValidDistribution() {
            this.$emit('validDoseDistribution', {idVaccine: this.idVaccineSelected, isValid: this.validDistributionUnit && this.validDistributionRegion});
        },

        setStatusRegionDistribution(isValid) {
            this.validDistributionRegion = isValid;
        },

        setStatusUnitDistribution(isValid) {
            this.validDistributionUnit = isValid;
        },

        blockAdvanceStep() {
            return !(this.disabledButtonWhenError || this.disabledButtonStep);
        },

        handleHasDistribution(distribution) {
            if(distribution.vaccineId == this.idVaccineSelected) {
                this.hasRegionDistribution = distribution.hasDistribution;
            }
        }
    },

    watch: {
        idVaccine(IdVaccine) {
            this.idVaccineSelected = IdVaccine;
        },

        vaccinesEdited(vaccines) {
            this.vaccinesEditedSelected = vaccines;
        },

        numberOfDoses: {
            handler (newValue) {
                this.selectedNumberOfDoses = newValue;
            },
            deep: true,
        },

        incentives(incentives) {
            this.selectedIncentives = incentives;

            this.fillIncentive();
        }
    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/variables/_variables.scss";

    .wizard-nav.wizard-nav-pills > li > a > span {
        margin-bottom: 12px;
        text-align: left;
        width: 130px;
    }

    .wizard-nav.wizard-nav-pills > li > a > div.checked > i {
        color: $custom-blue !important;
    }

    .wizard-nav.wizard-nav-pills > li > a > div.checked {
        background-color: rgba(37, 95, 182, 0.08) !important;
    }

    .wizard-nav.wizard-nav-pills > li:nth-child(1)::after {
        content: "Precificação de Doses";
        position: relative;
        bottom: 18px;
        left: 28px;
        color: #6E6B7B;
        font-size: 12px;
        font-weight: normal;
        word-break: keep-all;
        cursor: pointer
    }

    .wizard-nav.wizard-nav-pills > li:nth-child(1n+2)::after {
        content: "Distribuição de Doses";
        position: relative;
        bottom: 18px;
        left: 28px;
        color: #6E6B7B;
        font-size: 12px;
        font-weight: normal;
        word-break: keep-all;
        cursor: pointer
    }

    .steps-transparent .wizard-tab-content, .steps-transparent .wizard-card-footer {
        box-shadow: none;
        padding-top: 0
    }

    .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
        box-shadow: none !important;
    }
</style>
