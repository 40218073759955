<template>
    <section class="minimal-information">
        <main class="card p-2">
            <b-alert variant="custom-blue" :show="alertCanceledSchedule">
                <h4 class="alert-heading text-custom-blue">Campanha não lançada</h4>
                <div class="alert-body">
                    <span class="text-custom-blue">Clique em ”Lançar Campanha” e finalize o registro das últimas informações para lançá-la.</span>
                </div>
            </b-alert>

            <TimedAlert variant="warning" message="A doença selecionada não possui nenhum incentivo vinculado. Para mais informações entre em contato com suporte. " :show="noIncentiveAlert"/>

            <TopButtons />

            <InfosCampanhaForm 
                @selectedVaccines="handleselectedVaccines"
                :removedVaccina="removedVaccine"
                class="mb-2"
            />

            <DoseQuantification 
                v-if="showDoseQuantification" 
                :infosSelectedPreviousForm="basicInfosSelected"
                @removedVacina="removeVaccine"
                @numberOfDoses="handleChangeNumberOfDoses"
                :incentives="selectedIncentives"
            />
        </main>
    </section>
</template>

<script>
import { BButton, BBadge, BAlert } from 'bootstrap-vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import InfosCampanhaForm from '../Forms/InfosCampanhaForm.vue';
import DoseQuantification from '../Forms/DoseQuantification.vue';
import TopButtons from '@/views/components/custom/ButtonsCampanha/TopButtons.vue';

export default {
    components: {
        BButton, BBadge,
        InfosCampanhaForm, DoseQuantification, TimedAlert, BAlert, TopButtons
    },

    props: {
        incentives: {
            required: true,
            type: Object|null,
        },
        noIncentive: {
            required: true,
            type: Boolean,
        },
        alertCanceledScheduleCampaign: {
            required: true,
            type: Boolean,
        }
    },

    data() {
        return {
            showDoseQuantification: false,
            savingInfosCampanha: false,
            errorFormInfosCampanha: false,
            removedVaccine: null,
            basicInfosSelected: [],
            noIncentiveAlert: false,
            errorIncentive: false,
            selectedIncentives: this.$props.incentives,
            alertCanceledSchedule: false
        }
    },

    methods: {
        handleChangeNumberOfDoses(numberOfDoses) {
            this.$emit('numberOfDoses', numberOfDoses);
        },

        handleselectedVaccines(basicInfosSelected) {
            this.basicInfosSelected = basicInfosSelected;
            this.showDoseQuantification = true;
            this.$emit('changeVaccines', basicInfosSelected);
        },

        removeVaccine(vaccine) {
            if(vaccine[1] == 0) {
                this.showDoseQuantification = false;
            }

            this.removedVaccine = vaccine[0];
        }
    },

    watch: {
        incentives(value) {
            this.selectedIncentives = value;
        },

        noIncentive(hasIncentive) {
            if(hasIncentive) {
                this.noIncentiveAlert = !this.noIncentiveAlert;
                this.showDoseQuantification = false;
                this.errorIncentive = true;
                return;
            }

            this.showDoseQuantification = true;
        },

        alertCanceledScheduleCampaign(value) {
            this.alertCanceledSchedule = value;
        }
    }
}
</script>
