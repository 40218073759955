<template>
  <div class="ml-1">
        <span class="custom-font-bold text-dark">Doses disponíveis</span>
        <p class="margin-text-custom">Sem Fomento</p>

        <h1 v-if="parseInt(dosesNumber.controlDosesWithoutPromotion) >= 0" class="mb-1 text-custom-blue">{{ parseInt(dosesNumber.controlDosesWithoutPromotion) }}</h1>
        <h1 v-else class="mb-1 text-danger">+{{ parseInt(dosesNumber.controlDosesWithoutPromotion) * -1 }}</h1>

        <p v-if="hasIncentive" class="margin-text-custom">Com Fomento</p>
        
        <h1 v-if="parseInt(dosesNumber.controlDosesWithPromotion) >= 0 && hasIncentive" class="mb-1 text-custom-blue">{{ parseInt(dosesNumber.controlDosesWithPromotion) }}</h1>
        <h1 v-if="parseInt(dosesNumber.controlDosesWithPromotion) < 0 && hasIncentive" class="mb-1 text-danger">+{{ parseInt(dosesNumber.controlDosesWithPromotion) * -1 }}</h1>
        
        <span class="custom-font-bold text-dark">Doses distribuídas</span>
        <p class="margin-text-custom">Sem Fomento</p>
        <h1 class="mb-1 text-custom-blue">{{ parseInt(dosesNumber.totalDistributedDosesWithoutPromotion) }}</h1>
        <p v-if="hasIncentive" class="margin-text-custom">Com Fomento</p>
        <h1 v-if="hasIncentive" class="mb-1 text-custom-blue">{{ parseInt(dosesNumber.totalDistributedDosesWithPromotion) }}</h1>
    </div>
</template>

<script>
export default {

    props: {
        dosesNumber: {
            required: true,
            type: Object|null
        },
        hasIncentive: {
            required: true,
            type: Boolean  
        }
    },
}
</script>

<style>
.scrollAnimate {
    position: sticky;
    top: 100px;
}
</style>

