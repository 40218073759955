<template>
    <b-row>
        <b-col
            xl="7" lg="7" md="12" sm="12"
            class="pr-xl-2 pr-lg-2 pb-md-2 pr-sm-2"
            >

            <ErrorNumberOfDoses 
                :modalShow="modalErrorDoses.showModal"
                :typeModal="modalErrorDoses.type"
                @confirmed="closeModal"
            />

            <b-form>
                <app-collapse accordion>
                    <app-collapse-item
                        v-for="(region, index) in regions"
                        :key="index"
                        class="mb-1"
                        title="unidade"
                        :isVisible="visibleCollapse"
                        @visible="loadUnidades(region)"
                    >
                        <template slot="header">
                            <h4 class="text-custom-blue mb-0">{{ region.descricao }}</h4>
                        </template>

                        <div v-if="!region.unidades" class="text-center">
                            <div class="spinner-border text-custom-blue" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                        <b-table
                            v-else
                            ref="tableDosesUnit"
                            id="table-doses-unit"
                            responsive
                            :stickyColumn="true"
                            :fields="region.fields"
                            :items="region.unidades"
                            no-local-sorting
                            :no-sort-reset="true"
                            tbody-tr-class="custom-center-content"
                            table-class="custom-table"
                        >
                            <template #cell(actions)="row">
                                <feather-icon v-if="row.item.tooltip"
                                    v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
                                    title="Espaço para reserva de doses a serem distribuídas futuramente."
                                    icon="InfoIcon" 
                                    size="18"
                                    color="#2772C0"
                                />
                            </template>
                            <template #cell(descricao)="row">
                                <span class="custom-font-bold">{{ row.item.descricao }}</span>
                            </template>
                            <template #cell(noPromotion)="row">
                                <b-form-input 
                                    v-model="row.item.noPromotion"
                                    @keypress="$helpers.doNotAllowZeroValue($event, row.item, 'noPromotion')"
                                    v-mask="'##########'"
                                    autocomplete="off"
                                    class="custom-input-number-doses"
                                    id="no-promotion"
                                    @input="() => {
                                        CalcTable.sumAllRowTable(row.item);
                                        sumColumnDistribution(region);
                                        DistributionUnitService.checkIfOnlyHasReservation(busEvent, regions, selectedIdVaccine);
                                        fillSumary(region);
                                    }"
                                    placeholder="---"
                                />
                            </template>
                            <template #cell(withPromotion)="row">
                                <b-form-input 
                                    v-model="row.item.withPromotion"
                                    @keypress="$helpers.doNotAllowZeroValue($event, row.item, 'withPromotion')"
                                    v-mask="'##########'"
                                    autocomplete="off"
                                    class="custom-input-number-doses"
                                    id="with-promotion"
                                    @input="() => {
                                        CalcTable.sumAllRowTable(row.item);
                                        sumColumnDistribution(region);
                                        DistributionUnitService.checkIfOnlyHasReservation(busEvent, regions, selectedIdVaccine);
                                        fillSumary(region);
                                    }"
                                    placeholder="---"
                                />
                            </template>
                            <template #cell(totalDoses)="row">
                                <span class="custom-font-bold m-0">{{ row.item.totalDoses ? row.item.totalDoses : '---' }}</span>
                            </template>
                        </b-table>
                    </app-collapse-item>
                </app-collapse>
            </b-form>
        </b-col>
        <b-col lg="4" md="4" sm="2" class="separatorBar ml-2 pl-2 pb-5" id="doseSectionUnit">
            <DoseSummary 
                :dosesNumber="doses"
                :hasIncentive="hasIncentivePromotion"
            />
        </b-col>
    </b-row>
</template>

<script>
import { BTable, BForm, BRow, BCol, BFormInput } from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import DoseSummary from '@/views/components/custom/DosesSummary/DosesSummaryregionUnit.vue';
import ErrorNumberOfDoses from '@/views/components/custom/modals/ModalErrorNumberOfDoses.vue';
import CalcTable from '../../Services/CalculatetableDoses';
import FillUnidades from '../../Services/FillRegionsUnidade';
import TableFieldControl from '../../Services/TableFieldControl';
import DistributionUnitService from '../../Services/DistributionUnidadeFormService';

import { busEvent } from '@/main';
import followScreen from '@/views/components/custom/DosesSummary/followScreen';

export default {
    components: {
        BTable, BForm, BRow, BCol, BFormInput,
        AppCollapse, AppCollapseItem, DoseSummary,
        ErrorNumberOfDoses
    },

    props: {
        regionsSelected: {
            required: true,
            type: Object|null
        },
        hasPromotion: {
            required: true,
            type: Boolean,
        },
        incentives: {
            required: true,
            type: Object|null
        },
        idVacccine: {
            required: true,
            type: Number|null
        },
        numberOfDoses: {
            required: true,
            type: Object|null
        },
        vacinesEdited: {
            requried: true
        }
    },

    data() {
        return {
            DistributionUnitService,
            busEvent,
            regions: null,
            savedDistribution: false,
            hasIncentivePromotion: this.$props.hasPromotion,
            selectedIncentives: this.$props.incentives,
            selectedIdVaccine: this.$props.idVacccine,
            vaccinesEditedSelected: this.$props.vacinesEdited,
            CalcTable,
            countUnitRegionSaved: 0,
            doses: {
                totalDosesWithPromotion: 0,
                totalDosesWithoutPromotion: 0,

                reservationWithPromotion: 0,
                reservationWithoutPromotion: 0,

                totalDistributedDosesWithPromotion: 0,
                totalDistributedDosesWithoutPromotion: 0,

                controlDosesWithPromotion: 0,
                controlDosesWithoutPromotion: 0,
            },
            modalErrorDoses: {
                showModal: false,
                type: 'rascunho-unidade'
            },
            visibleCollapse: false,
        }
    },

    mounted() {
        busEvent.$on('saveCampanha', this.saveDataDistributionUnidade);
    },

    beforeDestroy() {
        busEvent.$off('saveCampanha', this.saveDataDistributionUnidade);
        window.addEventListener('scroll', followScreen.verifyScroll(false));
    },

    methods: {
        saveDataDistributionUnidade() {
            let dataDistribution = [];

            this.regions.map(region => {
                const dataReservation = DistributionUnitService.prepareDataToSaveReservation(
                    region.unidades, this.selectedIncentives
                );
                const dataDistributionDose = DistributionUnitService.prepareDataSaveDoseDistribution(
                    region, this.selectedIncentives
                );

                dataDistribution.push({
                    idRegion: region.idRegiao,
                    reserva: dataReservation ? dataReservation : [],
                    idVaccine: this.selectedIdVaccine,
                    distribution: dataDistributionDose ? dataDistributionDose : []
                });
            });

            busEvent.$emit('doseDistributionUnidadePrepare', dataDistribution);
        },

        loadUnidades(region, fillSumary = true) {
            FillUnidades.updateSummary(this, region);
            this.controlColumns(region);
            this.sumColumnDistribution(region);
            if(fillSumary) { this.fillSumary(region) }
            
            this.regions.map(r => {
                r.open = false;
            });

            region.open = true;

            if(open && !region.hasOwnProperty('unidades')) {
                this.$http.get(this.$api.unidadeRegiao(region.idRegiao)).then(({ data }) => {
                    this.regions.map((regionUnidade) => {
                        if(regionUnidade.idRegiao == region.idRegiao) {
                            FillUnidades.fillUnidadesByRegion(this, regionUnidade, data);
                            this.controlColumns(regionUnidade);      
                        }
                    })
                });
            }
        },

        fillSumary(region) {
            if(region.hasOwnProperty('dosesSummary')) {
                this.doses.totalDosesWithPromotion = region.dosesSummary.totalDosesWithPromotion;
                this.doses.totalDosesWithoutPromotion = region.dosesSummary.totalDosesWithoutPromotion;
                this.doses.controlDosesWithPromotion = region.dosesSummary.controlDosesWithPromotion;
                this.doses.controlDosesWithoutPromotion = region.dosesSummary.controlDosesWithoutPromotion;

                this.doses.totalDistributedDosesWithPromotion = region.dosesSummary.totalDistributedDosesWithPromotion;
                this.doses.totalDistributedDosesWithoutPromotion = region.dosesSummary.totalDistributedDosesWithoutPromotion;

                this.doses.reservationWithPromotion = region.dosesSummary.reservationWithPromotion;
                this.doses.reservationWithoutPromotion = region.dosesSummary.reservationWithoutPromotion;
            }
        },

        controlColumns(region) {
            if(region.hasOwnProperty('fields')) {
                TableFieldControl.removePromotionNoIncentive(this.hasIncentivePromotion, region.fields);
                TableFieldControl.removePromotionDoses(region.withPromotion, region.fields);
                TableFieldControl.removeWithoutPromotionDoses(region.noPromotion, region.fields);
                if(this.$refs.tableDosesUnit) {
                    this.$nextTick(() => {
                        this.$refs.tableDosesUnit.map((table) => {
                            table.refresh();
                        })
                    })   
                }
            }
        },

        sumColumnDistribution(region) {
            if(region.hasOwnProperty('unidades')) {
                CalcTable.sumAllColumTable(region.dosesSummary, region.unidades);
            }
        },

        closeModal() {
            this.modalErrorDoses.showModal = false;
        },

        validadeNumberOfDosesDistributed(noOpenModal = true) {
            let error = false;

            const regionsWithUnits = this.regions.filter(region => region.unidades);
            
            regionsWithUnits.map(region => {
                const totalDosesDistributedNoPromotion = region.unidades.reduce(
                    (accumulator, unidade) => accumulator + (unidade.noPromotion ? parseInt(unidade.noPromotion) : 0)
                , 0);

                const totalDosesDistributedWithPromotion = region.unidades.reduce(
                    (accumulator, unidade) => accumulator + (unidade.withPromotion ? parseInt(unidade.withPromotion) : 0)
                , 0);

                if(totalDosesDistributedNoPromotion == 0 && totalDosesDistributedWithPromotion == 0) {
                    error = false;
                }

                if(
                    (totalDosesDistributedNoPromotion > region.dosesSummary.totalDosesWithoutPromotion) || 
                    (totalDosesDistributedWithPromotion > region.dosesSummary.totalDosesWithPromotion)
                ) {
                    if(noOpenModal){
                        this.modalErrorDoses.showModal = true;
                    }
                    this.$emit('errorNumberDoses', this.selectedIdVaccine);

                    error = true;
                }
            });

            busEvent.$emit('hasNoErrorsDistribution');

            return error;
        },

        validateNotDistributed() {
            let error = false;

            const regionsWithUnits = this.regions.filter(region => region.unidades);

            if(regionsWithUnits.length == 0) {
                return true;
            }

            regionsWithUnits.map(region => {
                const totalDosesDistributedNoPromotion = region.unidades.reduce(
                    (accumulator, unidade) => accumulator + (unidade.noPromotion ? parseInt(unidade.noPromotion) : 0)
                , 0);

                const totalDosesDistributedWithPromotion = region.unidades.reduce(
                    (accumulator, unidade) => accumulator + (unidade.withPromotion ? parseInt(unidade.withPromotion) : 0)
                , 0);

                if(totalDosesDistributedNoPromotion == 0 && totalDosesDistributedWithPromotion == 0) {
                    error = true;
                }
            });

            return error;
        },

        validateHasDosesAvailable() {
            let allDosesAvailable = 0;

            this.regions.map(region => {
                allDosesAvailable += region.dosesSummary.controlDosesWithPromotion + region.dosesSummary.controlDosesWithoutPromotion;
            });

            if(allDosesAvailable == 0 && (this.doses.totalDistributedDosesWithPromotion > 0 || this.doses.totalDistributedDosesWithoutPromotion > 0)){
                busEvent.$emit('canSaveScheduleDistributionUnidade', [true, this.selectedIdVaccine]);
            } else {
                busEvent.$emit('canSaveScheduleDistributionUnidade', [false, this.selectedIdVaccine]);
            }

            allDosesAvailable = 0;
        }
    },

    watch: {
        regionsSelected(regions) {
            this.regions = regions;

            this.regions.map((region) => {
                this.loadUnidades(region, false);
            });
        },

        hasPromotion(value) {
            this.hasIncentivePromotion = value; 

            if(this.regions) {
                this.regions.map(region => {
                    TableFieldControl.removePromotionNoIncentive(value, region.fields);
                });
            }
        },

        idVacccine(idVaccine) {
            this.selectedIdVaccine = idVaccine;
        },

        incentives(incentives) {
            this.selectedIncentives = incentives;
        },

        vacinesEdited(vaccines) {
            this.vaccinesEditedSelected = vaccines;
            this.validateHasDosesAvailable();
        },
        
        numberOfDoses: {
            handler () {
                this.regions.map((region) => {
                    if(region.open){
                        FillUnidades.updateSummary(this, region);
                        this.controlColumns(region);
                        this.sumColumnDistribution(region);
                    }
                });

                this.validateHasDosesAvailable();
            },
            deep: true,
        },
        
        doses: {
            handler () {
                this.visibleCollapse = false;
                this.validateHasDosesAvailable();

                let hasError = this.validadeNumberOfDosesDistributed(false);
                this.$emit('validDoseDistribution', !hasError);
            },
            deep: true,
        },
    }
}
</script>

<style scoped>
.custom-width-actions-table{
    min-width: 30px;
}

.custom-tooltip {
    font-size: 14px;
}

.separatorBar {
    border-left: 1px solid #EBE9F1;
    height: 100%;
}
</style>