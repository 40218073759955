var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"formCampanha mt-2"},[_c('validation-observer',{ref:"campanhaRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"pr-lg-0",attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome da Campanha*","label-for":"name-campanha"}},[_c('validation-provider',{attrs:{"name":"nameCampanha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(('X'.repeat(200))),expression:"('X'.repeat(200))"}],attrs:{"state":errors.length > 0 || _vm.form.errors.nameCampanha ? false:null,"id":"name-campanha","autocomplete":"off","placeholder":"Dê o nome da campanha"},model:{value:(_vm.form.nameCampanha),callback:function ($$v) {_vm.$set(_vm.form, "nameCampanha", $$v)},expression:"form.nameCampanha"}}),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.nameCampanha)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.nameCampanha)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"pr-lg-0",attrs:{"lg":"3","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Doenca","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Doença*","label-for":"disease","state":errors.length > 0 || _vm.form.errors.disease ? false:null}},[_c('v-select',{attrs:{"id":"disease","loading":_vm.loadingDoencas,"reduce":(function (option) { return option.id_doenca; }),"variant":"primary","options":_vm.disease,"label":"nome_tecnico","placeholder":"Selecione uma doença"},on:{"input":_vm.selectedDisease},model:{value:(_vm.form.disease),callback:function ($$v) {_vm.$set(_vm.form, "disease", $$v)},expression:"form.disease"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.disease)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.disease)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"lg":"5","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Vacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Vacina*","label-for":"vaccines","state":errors.length > 0 || _vm.form.errors.vaccines ? false:null}},[_c('v-select',{attrs:{"id":"vaccines","loading":_vm.loadingVacinas,"options":_vm.vaccines,"closeOnSelect":false,"disabled":_vm.selectVaccines,"multiselect":"","multiple":"","selectable":function () { return _vm.form.vaccines.length < 3; },"label":"nome_tecnico","placeholder":"Selecione uma ou mais"},on:{"input":_vm.selectedVaccines,"option:deselected":_vm.emitEventSelectedVaccines},model:{value:(_vm.form.vaccines),callback:function ($$v) {_vm.$set(_vm.form, "vaccines", $$v)},expression:"form.vaccines"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.vaccines)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.vaccines)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{staticClass:"mt-2",attrs:{"lg":"12","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"adhesion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('adhesion-no-workers-input',{on:{"setPartialAdhesion":_vm.setPartialAdhesion}}),_c('input',{attrs:{"type":"hidden","name":"adhesion"},domProps:{"value":_vm.adhesion}}),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }