export default {
    sumAllRowTable(item) {
        if(item.noPromotion && item.withPromotion) {
            item.totalDoses = parseInt(item.noPromotion) + parseInt(item.withPromotion);
            return;
        }

        if(item.noPromotion) {
            item.totalDoses = item.noPromotion;
        } else {
            item.totalDoses = item.withPromotion;
        }
    },

    sumAllColumTable(doses, items) {
        doses.totalDistributedDosesWithPromotion = 0;
        doses.totalDistributedDosesWithoutPromotion = 0;

        items.map(item => {
            if(item.idRegiao || item.idUnidade) {
                doses.totalDistributedDosesWithPromotion += item.withPromotion ? parseInt(item.withPromotion) : 0;
                doses.totalDistributedDosesWithoutPromotion += item.noPromotion ? parseInt(item.noPromotion) : 0;
            }

            if(!item.idRegiao && !item.idUnidade) {
                doses.reservationWithPromotion = item.withPromotion ? parseInt(item.withPromotion) : 0;
                doses.reservationWithoutPromotion = item.noPromotion ? parseInt(item.noPromotion) : 0;
            }
        })

        this.calcDisponibility(doses);
    },

    calcDisponibility(doses) {
        doses.controlDosesWithPromotion = (doses.totalDosesWithPromotion - doses.reservationWithPromotion) - doses.totalDistributedDosesWithPromotion;
        doses.controlDosesWithoutPromotion = (doses.totalDosesWithoutPromotion - doses.reservationWithoutPromotion) - doses.totalDistributedDosesWithoutPromotion;
    },
}