<template>
    <b-row>
        <b-col 
            xl="7" lg="7" md="12" sm="12"
            class="pr-xl-2 pr-lg-2 pb-md-2 pr-sm-2">

            <ListOperatingUnitsRegionModal 
                :modalShow="modal.showModal"
                :idRegiao="modal.idRegion"
                :nameRegion="modal.nameRegion"
                @confirmed="closeModal"
            />

            <ErrorNumberOfDoses 
                :modalShow="modalErrorDoses.showModal"
                :typeModal="modalErrorDoses.type"
                @confirmed="closeModal"
            />

            <section class="distribution-doses-regiao">
                <div class="mb-1 d-flex justify-content-end align-items-center">
                    <p class="m-0 mr-1 custom-tooltip" for="">Buscar</p>
                    <b-input-group class="input-group-merge input-width-search">
                        <b-form-input 
                            v-model="filterTable"
                            class="custom-input-search"
                        />
                        <b-input-group-append is-text>
                            <feather-icon
                                icon="SearchIcon"
                                color="#B9B9C3"
                                class="cursor-pointer"
                            />
                        </b-input-group-append>
                    </b-input-group>
                </div>

                <b-table
                    ref="tableDoses"
                    id="table-doses-regiao"
                    responsive
                    :busy.sync="filtering"
                    :stickyColumn="true"
                    :fields="table.fields"
                    :items="table.regioes"
                    :filter="filterTable"
                    no-local-sorting
                    :no-sort-reset="true"
                    tbody-tr-class="custom-center-content"
                    table-class="custom-table"
                >
                    <template #cell(actions)="row">
                        <feather-icon v-if="row.item.tooltip"
                            v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
                            title="Espaço para reserva de doses a serem distribuídas futuramente."
                            icon="InfoIcon" 
                            size="18"
                            color="#2772C0"
                        />
                        <feather-icon v-else
                            @click="openModalListUos(row.item)"
                            icon="EyeIcon" 
                            class="c-pointer"
                            size="18"
                            color="#2772C0"
                        />
                    </template>
                    <template #cell(descricao)="row">
                        <span class="custom-font-bold">{{ row.item.descricao }}</span>
                    </template>
                    <template #cell(noPromotion)="row">
                        <b-form-input 
                            v-model="row.item.noPromotion"
                            @keypress="$helpers.doNotAllowZeroValue($event, row.item, 'noPromotion')"
                            v-mask="'##########'"
                            autocomplete="off"
                            id="no-promotion"
                            class="custom-input-number-doses"
                            @input="() => {
                                CalcTable.sumAllRowTable(row.item); 
                                CalcTable.sumAllColumTable(doses, table.regioes); 
                                liberaRegiosUnidade() 
                            }"
                            placeholder="---"
                        />
                    </template>
                    <template #cell(withPromotion)="row">
                        <b-form-input 
                            v-model="row.item.withPromotion"
                            @keypress="$helpers.doNotAllowZeroValue($event, row.item, 'withPromotion')"
                            v-mask="'##########'"
                            autocomplete="off"
                            class="custom-input-number-doses"
                            id="with-promotion"
                            @input="() => {
                                CalcTable.sumAllRowTable(row.item),
                                CalcTable.sumAllColumTable(doses, table.regioes); 
                                liberaRegiosUnidade() 
                            }"
                            placeholder="---"
                        />
                    </template>
                    <template #cell(totalDoses)="row">
                        <span class="custom-font-bold m-0">{{ row.item.totalDoses ? row.item.totalDoses : '---' }}</span>
                    </template>
                </b-table>
            </section>
        </b-col>
        <!-- ml-2 pl-2 pb-5 -->
        <b-col 
            xl="5" lg="5" md="12" sm="12" 
            class="separatorBar 
            pl-xl-0 pr-lg-0 pr-md-0 pr-sm-0
            "
            id="doseSection">
            <DoseSummary 
                :dosesNumber="doses"
                :hasIncentive="hasIncentivePromotion"
            />
        </b-col>
    </b-row>
</template>

<script>
import { BTable, BFormInput,  BRow, BCol, VBTooltip, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';

import ListOperatingUnitsRegionModal from '@/views/components/custom/modals/ListOperatingUnitsRegionModal.vue'
import ErrorNumberOfDoses from '@/views/components/custom/modals/ModalErrorNumberOfDoses.vue';
import DoseSummary from '@/views/components/custom/DosesSummary/DosesSummaryregionUnit.vue';
import CalcTable from '../../Services/CalculatetableDoses';
import TableFieldControl from '../../Services/TableFieldControl';
import DoseDistributionRegionFormService from '../../Services/DoseDistributionRegionFormService';

import { busEvent } from '@/main';
import followScreen from '@/views/components/custom/DosesSummary/followScreen';

export default {
    components: {
        BTable, BFormInput, BRow, BCol, VBTooltip,
        BInputGroup, BInputGroupAppend,
        ListOperatingUnitsRegionModal, ErrorNumberOfDoses,
        DoseSummary
    },

    props: {
        hasPromotion: {
            required: true,
            type: Boolean,
        },
        incentives: {
            required: true,
            type: Object|null
        },
        numberOfDoses: {
            required: true,
            type: Object|null
        },
        hasRegion: {
            required: true,
            type: Boolean
        },
        vacinesEdited: {
            requried: true
        },
        idVacccine: {
            required: true,
            type: Number|null
        }
    },

    data() {
        return {
            filtering: true,
            filterTable: null,
            savedDistribution: false,
            hasIncentivePromotion: this.$props.hasPromotion, 
            selectedIncentives: this.$props.incentives,
            selectedNumberOfDoses: this.$props.numberOfDoses,
            selectedIdVaccine: this.$props.idVacccine,
            vaccinesEditedSelected: this.$props.vacinesEdited,
            departamentNoRegionReservationWithPromotion: 0,
            departamentNoRegionReservationWithoutPromotion: 0,
            doses: {
                totalDosesWithPromotion: 0,
                totalDosesWithoutPromotion: 0,

                reservationWithPromotion: 0,
                reservationWithoutPromotion: 0,

                totalDistributedDosesWithPromotion: 0,
                totalDistributedDosesWithoutPromotion: 0,

                controlDosesWithPromotion: 0,
                controlDosesWithoutPromotion: 0,
            },
            modal: {
                showModal: false,
                idRegion: null,
                nameRegion: null,
            },
            modalErrorDoses: {
                showModal: false,
                type: 'rascunho-regiao'
            },
            CalcTable,
            table: {
                fields: [
                    {key: 'actions', label: '', class: 'custom-width-actions-table px-1'},
                    {key: 'descricao', label: 'Região', class: 'pl-1'},
                    {key: 'noPromotion', label: 'Sem fomento'},
                    {key: 'withPromotion', label: 'Com fomento'},
                    {key: 'totalDoses', label: 'Total', class: 'text-center'},
                ],
                regioes: [
                    {
                        descricao: 'Reserva do Dep. Regional',
                        idRegiao: null,
                        noPromotion: null,
                        withPromotion: null,
                        totalDoses: null,
                        tooltip: true,
                    }
                ],
            },
            noRegion: false
        }
    },

    mounted() {
        this.loadRegioes();
        this.verifyIfDepartamentHasUnits();
        window.addEventListener('scroll', followScreen.verifyScroll())
        if(this.$props.numberOfDoses) {
            this.thingsToDoWhenChangeNumberOfDoses(this.$props.numberOfDoses);
        }


        this.$emit('regionsDistributeUnidade', [[], true]);

        busEvent.$on('saveCampanha', this.saveCampaignListener);
        busEvent.$on('checkHasDistributionRegion', this.checkIfHasDistribution);
    },

    beforeDestroy() {
        busEvent.$off('saveCampanha', this.saveCampaignListener);
        window.removeEventListener('scroll', followScreen.verifyScroll())
        busEvent.$off('checkHasDistributionRegion', this.checkIfHasDistribution);
    },

    methods: {
        saveCampaignListener() {
            const reservation = DoseDistributionRegionFormService.prepareDataToSaveReservation(
                this.doses, this.selectedNumberOfDoses
            );
            const DistributionRegion = DoseDistributionRegionFormService.prepareDataSaveDoseDistribution(
                this.table.regioes, this.selectedIncentives
            );

            busEvent.$emit('distributionRegionPrepare', { 
                idVaccine: this.selectedIdVaccine, 
                reservation, DistributionRegion 
            });
        },

        openModalListUos(region) {
            this.modal.idRegion = region.idRegiao;
            this.modal.nameRegion = region.descricao;
            this.modal.showModal = true;
        },

        loadRegioes() {
            this.$http.get(this.$api.regiao(), { 
                params: { 
                    ativo: true 
                } 
            }).then(({ data }) => {
                if(data.length){
                    data.map(regiao => {
                        this.table.regioes.push({
                            descricao: regiao.descricao,
                            idRegiao: regiao.id_regiao,
                            noPromotion: null,
                            withPromotion: null,
                            totalDoses: null
                        })
                    })
                }else{
                    this.noRegion = true;
                    this.$emit('noRegion');
                }

                this.filtering = false;
            });
        },

        verifyIfDepartamentHasUnits() {
            this.$http.get(this.$api.unidade(), { 
                params: { 
                    ativo: true 
                } 
            }).then(({ data }) => {
                if(!data.length){
                    this.$emit('noUnit');
                }
            });
        },

        closeModal() {
            this.modal.showModal = false;
            this.modalErrorDoses.showModal = false;
        },

        fillDosesDistribution(doses) {
            this.doses.totalDosesWithPromotion = 0;
            this.doses.controlDosesWithPromotion = 0;
            this.doses.totalDosesWithoutPromotion = 0;
            this.doses.controlDosesWithoutPromotion = 0

            this.doses.totalDosesWithPromotion = doses.numberOfDosesWithPromotion.quantity ? parseInt(doses.numberOfDosesWithPromotion.quantity) : 0;
            this.doses.controlDosesWithPromotion = doses.numberOfDosesWithPromotion.quantity ? parseInt(doses.numberOfDosesWithPromotion.quantity) : 0;
            this.doses.totalDosesWithoutPromotion = doses.numberOfDosesWithoutPromotion.quantity ? parseInt(doses.numberOfDosesWithoutPromotion.quantity) : 0;
            this.doses.controlDosesWithoutPromotion = doses.numberOfDosesWithoutPromotion.quantity ? parseInt(doses.numberOfDosesWithoutPromotion.quantity) : 0;
        },

        thingsToDoWhenChangeNumberOfDoses(numberOfDoses) {
            this.fillDosesDistribution(numberOfDoses);
            TableFieldControl.removePromotion(this.hasIncentivePromotion, this.doses, this.table);
            TableFieldControl.removeWithoutPromotion(this.doses, this.table);
            CalcTable.sumAllColumTable(this.doses, this.table.regioes);
            this.$refs.tableDoses.refresh();
        },

        validadeNumberOfDosesDistributed(noOpenModal = true) {
            const regionWithoutReservation = this.table.regioes.filter(region => region.idRegiao);

            const totalDosesDistributedNoPromotion = regionWithoutReservation.reduce(
                (accumulator, region) => accumulator + (region.noPromotion ? parseInt(region.noPromotion) : 0)
            , 0);

            const totalDosesDistributedWithPromotion = regionWithoutReservation.reduce(
                (accumulator, region) => accumulator + (region.withPromotion ? parseInt(region.withPromotion) : 0)
            , 0);

            if(totalDosesDistributedNoPromotion == 0 && totalDosesDistributedWithPromotion == 0) {
                return false;
            }

            const totalDosesNoPromotion = this.doses.totalDosesWithoutPromotion - this.doses.reservationWithoutPromotion;
            const totalDosesWithPromotion = this.doses.totalDosesWithPromotion - this.doses.reservationWithPromotion;

            if(
                (totalDosesDistributedNoPromotion > totalDosesNoPromotion) || 
                (totalDosesDistributedWithPromotion > totalDosesWithPromotion)
            ) {
                
                if(noOpenModal) {
                    this.modalErrorDoses.showModal = true;
                }
                this.$emit('errorNumberDoses', this.selectedIdVaccine);

                return true;
            }

            busEvent.$emit('hasNoErrorsDistribution');

            return false;
        },

        validateReservation(noOpenModal = true) {
            if(this.doses.reservationWithoutPromotion > this.doses.totalDosesWithoutPromotion ||
                this.doses.reservationWithPromotion > this.doses.totalDosesWithPromotion
            ){
                if(noOpenModal) {
                    this.modalErrorDoses.showModal = true;
                }
                this.$emit('errorNumberDoses', this.selectedIdVaccine);

                return true;
            }
            busEvent.$emit('hasNoErrorsDistribution');
        },

        liberaRegiosUnidade() {
            const regionWithoutReservation = this.table.regioes.filter(region => region.idRegiao);

            let regioesDistributeUnidade = [];

            regionWithoutReservation.map(region => {
                if(region.noPromotion || region.withPromotion) {
                    regioesDistributeUnidade.push(region);
                }
            });

            const error = this.validadeNumberOfDosesDistributed(false);
            this.validateHasDosesAvailable();

            this.$emit('regionsDistributeUnidade', [regioesDistributeUnidade, error]);
        },

        validateHasDosesAvailable() {
            if(this.doses.controlDosesWithoutPromotion == 0 && this.doses.controlDosesWithPromotion == 0 
            && (this.doses.totalDosesWithPromotion > 0 || this.doses.totalDosesWithoutPromotion > 0)) {
                busEvent.$emit('canSaveScheduleDistributionRegion', [true, this.selectedIdVaccine]);
                return;
            }

            busEvent.$emit('canSaveScheduleDistributionRegion', [false, this.selectedIdVaccine]);
        },

        checkIfHasDistribution(vaccineId) {
            if(this.selectedIdVaccine == vaccineId) {
                const reservation = DoseDistributionRegionFormService.prepareDataToSaveReservation(
                    this.doses, this.selectedNumberOfDoses
                );
                const distribution = DoseDistributionRegionFormService.prepareDataSaveDoseDistribution(
                    this.table.regioes, this.selectedIncentives
                );

                if(distribution.length > 0 || reservation) {
                    busEvent.$emit('hasDistribution', {hasDistribution: true, vaccineId});
                    return;
                }
                busEvent.$emit('hasDistribution', {hasDistribution: false, vaccineId});
            }
        }
    },

    watch: {
        hasPromotion(value) {
            this.hasIncentivePromotion = value;
            TableFieldControl.removePromotion(value, this.doses, this.table);
        },

        numberOfDoses: {
            handler (newValue) {
                this.thingsToDoWhenChangeNumberOfDoses(newValue);
                this.selectedNumberOfDoses = newValue;
                this.validateHasDosesAvailable();
            },
            deep: true,
        },

        idVacccine(idVaccine) {
            this.selectedIdVaccine = idVaccine;
        },

        incentives(incentives) {
            this.selectedIncentives = incentives;
        },

        vacinesEdited(vaccines) {
            this.vaccinesEditedSelected = vaccines;
            this.validateHasDosesAvailable();
        },

        doses: {
            handler () {
                if(!this.$props.hasRegion && this.table.regioes[1]){
                    this.table.regioes[1].noPromotion = this.doses.totalDosesWithoutPromotion;
                    this.table.regioes[1].withPromotion = this.doses.totalDosesWithPromotion;
                    this.liberaRegiosUnidade();
                }

                this.validateHasDosesAvailable();

                let hasError = this.validadeNumberOfDosesDistributed(false) || this.validateReservation(false) ? true : false;
                this.$emit('validDoseDistribution', !hasError);
            },
            deep: true,
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.custom-input-number-doses {
    border-color: $custom-blue;
    background: rgba(39, 114, 192, 0.04);
    max-width: 108px;
    height: 34px;
    text-align: center;
}

.custom-input-number-doses::placeholder {
    color: #5E5873;
}

.custom-table thead tr th{
    background: $custom-blue !important;
    color: white;
}

.custom-table {
    min-width: 400px !important;
}

.custom-width-actions-table{
    min-width: 30px;
}

.custom-font-bold {
    font-weight: 800;
}

.custom-tooltip {
    font-size: 14px;
}

.c-pointer {
    cursor: pointer;
}

.margin-text-custom {
    margin: 0;
    margin-top: 0.4rem;
}

.custom-input-search {
    height: 30px;
}

.input-width-search {
    width: 305px;
}

.separatorBar {
    border-left: 1px solid #EBE9F1;
    height: 100%;
}
</style>