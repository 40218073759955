import { isNumber, isString } from '@vueuse/shared';

export default {
    checkIfOnlyHasReservation(busEvent, regions, idVaccineSelected) {
        let hasOnlyReservation = [];

        regions.map(region => {
            let onlyReservation = false;

            if(region.unidades) {
                region.unidades.map(unidade => {
                    if(!isNumber(unidade.idUnidade) && isString(unidade.totalDoses)) {
                        onlyReservation = true;
                    } else if (isNumber(unidade.idUnidade) && isString(unidade.totalDoses)){
                        onlyReservation = false;
                    }
                })
            }

            if(onlyReservation) {
                hasOnlyReservation.push(region.idRegiao);
            }
        })

        busEvent.$emit('canSaveSchedulePrice', [!hasOnlyReservation.length > 0, idVaccineSelected]);
    },

    prepareDataToSaveReservation(unidades, selectedIncentives) {
        let reservas = [];

        if(!unidades) {
            return null;
        }

        const reservation = unidades.filter(unidade => !unidade.idUnidade)[0];

        if(!reservation.noPromotion && !reservation.withPromotion) { return null; }

        const promotion = selectedIncentives.filter(inccentive => inccentive.nome !== 'Normal');
        const noPromotion = selectedIncentives.filter(inccentive => inccentive.nome == 'Normal');

        if(reservation.withPromotion) {
            reservas.push({
                id_incentivo: promotion[0].id_incentivo,
                reserva: parseInt(reservation.withPromotion)
            });
        }

        if(reservation.noPromotion) {
            reservas.push({
                id_incentivo: noPromotion[0].id_incentivo,
                reserva: parseInt(reservation.noPromotion)
            });
        }

        return reservas;
    },

    prepareDataSaveDoseDistribution(region, selectedIncentives) {
        if(!region || !region.hasOwnProperty('unidades')) {
            return [];
        }

        const unidadesWithoutReservation = region.unidades.filter(unidade => unidade.idUnidade);

        let unitsToSave = [];
        
        const promotion = selectedIncentives.filter(inccentive => inccentive.nome !== 'Normal');
        const noPromotion = selectedIncentives.filter(inccentive => inccentive.nome == 'Normal');

        unidadesWithoutReservation.map((unit) => {
            if(unit.noPromotion && noPromotion && unit.noPromotion != 0) {
                unitsToSave.push({
                    id_incentivo: noPromotion[0].id_incentivo,
                    id_unidade: unit.idUnidade,
                    quantidade: unit.noPromotion
                });
            }

            if(unit.withPromotion && promotion && unit.withPromotion != 0) {
                unitsToSave.push({
                    id_incentivo: promotion[0].id_incentivo,
                    id_unidade: unit.idUnidade,
                    quantidade: unit.withPromotion
                });
            }
        });

        return unitsToSave;
    },
}